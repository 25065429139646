import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Embed Code`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` embed code allows `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` `}<a parentName="p" {...{
        "href": "/owners",
        "title": "owners"
      }}>{`owners`}</a>{` to add the conversation to any third party website they control in the form of an iframe generated by an html script tag. This does mean you have to allow third party JavaScript to run on your property. This is unlike YouTube, for instance, which only requires an iframe: embedded YouTube videos are not `}<a parentName="p" {...{
        "href": "https://css-tricks.com/fluid-width-video/#but-but-aspect-ratios-legacy-content-non-tech-users-etc"
      }}>{`responsive`}</a>{` to browser width. Polis is, and `}<a parentName="p" {...{
        "href": "https://css-tricks.com/fluid-width-video/#but-but-aspect-ratios-legacy-content-non-tech-users-etc"
      }}>{`uses JavaScript to solve this problem reliably`}</a>{`.`}</p>
    <p>{`The code that generates the iframe is open source and can be found here: `}<a parentName="p" {...{
        "href": "https://pol.is/embed.js"
      }}>{`https://pol.is/embed.js`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/blob/dev/client-participation/api/embed.js"
      }}>{`https://github.com/compdemocracy/polis/blob/dev/client-participation/api/embed.js`}</a></p>
    <h3>{`Events`}</h3>
    <p>{`The Polis embed code and iframe are configured to emit a "vote" event each time a participant votes. You can subscribe to those events on the page that instantiates the iframe like this (assuming you're using the hosted instance at `}<a parentName="p" {...{
        "href": "/pol.is",
        "title": "pol.is"
      }}>{`pol.is`}</a>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`window.addEventListener("message", function(event) {
  var data = event.data || {};
  if (!event.origin.match(/pol.is\\$/)) { return; }
  if (data.name === "vote") { alert("vote!");
}
`}</code></pre>
    <h3>{`Per User Config`}</h3>
    <p>{`Each time Polis loads into an embedded context, you can `}<strong parentName="p">{`optionally`}</strong>{` configure Polis at the user level to hide or show facets of the interface. You can do this by adding different flags to the `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` shown below. You might set `}<inlineCode parentName="p">{`data-ucw`}</inlineCode>{` (user can write) false, for instance, if the user isn't logged into your system. This allows flexible usage and deployment of the system.`}</p>
    <p>{`Using `}<inlineCode parentName="p">{`data-`}</inlineCode>{` attributes to customize behavior is an established pattern in web engineering. If you're a web engineer interested in examining best practices, see Google's implementation of programmable search: `}<a parentName="p" {...{
        "href": "https://developers.google.com/custom-search/docs/element"
      }}>{`https://developers.google.com/custom-search/docs/element`}</a>{` which uses the same combination of `}<inlineCode parentName="p">{`<div class="`}</inlineCode>{` and a script tag which targets the element's class attribute, using `}<inlineCode parentName="p">{`data-`}</inlineCode>{` attributes to override default behavior.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<div
  class="polis"
  data-page_id="your_pageid_1234"
  data-site_id="polis_site_id_your_site_id_goes_here"
  data-ucv="true"
  data-ucw="false"
  data-show_vis="true"
></div>
<script async="true" src="https://preprod.pol.is/embed.js"></script>
`}</code></pre>
    <h5>{`Hiding the comment submit form`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`data-ucw`}</inlineCode>{` (user can write) tag toggles writing, and is only relevant for that user for that session.`}</p>
    <h5>{`Hiding voting interface`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`data-ucv`}</inlineCode>{` (user can vote) tag toggles voting, and is only relevant for that user for that session.`}</p>
    <h5>{`Hiding the visualization`}</h5>
    <p>{`The `}<inlineCode parentName="p">{`data-show_vis`}</inlineCode>{` is a toggle that causes the vis to be enabled or disabled when the conversation initializes. This is a one-time setting which is picked up and stored in our db the first time someone loads this script. This is a convenience method if you are creating conversations implicitly from embed codes, rather than from the admin dash.`}</p>
    <p>{`If you later change this value in the embed code, it won't have any effect. To change that value, you can load the `}<a parentName="p" {...{
        "href": "configuration"
      }}>{`configuration`}</a>{` tab of the admin page for that conversation (and of course ensure an embed code isn't resetting it).`}</p>
    <h5>{`Full list:`}</h5>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`xid`}</inlineCode>{`: linkage to external identity`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucv`}</inlineCode>{`: user can `}<a parentName="li" {...{
          "href": "/vote",
          "title": "vote"
        }}>{`vote`}</a>{``}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucw`}</inlineCode>{`: user can write `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{``}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucsh`}</inlineCode>{`: user can see header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucsd`}</inlineCode>{`: user can see description`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucsf`}</inlineCode>{`: user can see footer`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`show_vis`}</inlineCode>{`: user can see visualization`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`build`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x_name`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`x_profile_image_url`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ui_lang`}</inlineCode>{`: set the language of the participation interface for all participants. This relies on the existence of `}<a parentName="li" {...{
          "href": "/community-contributed-translations",
          "title": "community contributed translations"
        }}>{`community contributed translations`}</a>{` for the given language`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ucsv`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`ucst`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`subscribe_type`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`show_share`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`bg_white`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`auth_needed_to_vote`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`auth_needed_to_write`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`auth_opt_fb`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`auth_opt_tw`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`auth_opt_allow_3rdparty`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dwok`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`topic`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      